import * as React from 'react';
import App from '../components/App';
import Page from '../components/Page';
import { Menu } from '../components/Menu';
import { graphql, Link } from 'gatsby';
import { FaComment } from 'react-icons/fa';

import styled from 'styled-components';
import { Titles } from '../components/Titles';
import moment from 'moment';
import { Boxy } from '../styles';

const IndexApp = styled(App)`
  max-width: 1024px;
`;

const IndexGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 1em;
  @media screen and (max-width: 40em) {
    display: block;
  }
`;

const List = styled.ul`
  margin: 0;
  list-style: none;
  padding-left: 0;
  & a {
    text-decoration: none;
    &:hover {
      text-decoration: underline !important;
      color: var(--accent-color);
    }
    color: black;
  }
`;

const Meta = styled.p`
  font-size: 0.75em;
  & .light {
    opacity: 0.6;
  }
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
  font-family: var(--header-font);
`;

const Title = styled.h3`
  margin-bottom: 0em;
`;

const Label = styled(Link)`
  font-family: var(--header-font);
  background-color: var(--neutral-background-color);
  font-size: 0.75em;
  padding: 0.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
`;

const Lede = styled.p`
  margin-top: 0.75em;
`;

const Pagination = styled.div`
  margin-top: 3em;
  margin-bottom: 3em;
  padding: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-family: var(--header-font);
  & span {
    text-align: center;
  }
  & a {
    text-decoration: none;
  }
  --boxy-color: #aaa;
  ${Boxy}
`;

const TitleLink = styled(Link)`
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline !important;
    color: var(--link-color);
  }
`;

const MainColumn = styled.div`
  grid-column: 1;
  padding: 0.5em;
`;

const RightRail = styled.div`
  ${Boxy}
  grid-column: 2;
  padding: 0.5em;
  --boxy-color: gray;
`;

const NodeList = ({ nodes = [] }) => (
  <List>
    {nodes.map((node) => (
      <li key={node.id} style={{ marginBottom: '3em' }}>
        <Title>
          <Link to={node.path}>{node.title}</Link>
        </Title>
        <Meta>
          <span className="light">
            {moment(node.date).format('DD MMMM YYYY')}
          </span>
          {node.project && (
            <Label
              to={node.project.slug}
              title={`Project: ${node.project.name}`}
            >
              {node.project.name}
            </Label>
          )}
        </Meta>
        <Lede>{node.lede}</Lede>
      </li>
    ))}
  </List>
);

const ProjectList = ({ projects = [] }) => (
  <List>
    {projects.map((project) => (
      <li key={project.id} style={{ marginBottom: '2em' }}>
        <h4>
          <TitleLink to={project.slug}>{project.id}</TitleLink>
        </h4>
        <Meta style={{ fontSize: '60%' }}>
          <span>
            {project.fields.numPosts} post
            {project.fields.numPosts === 1 ? '' : 's'}
          </span>
          <span>
            {moment(project.fields.lastPostDate).format('DD MMMM YYYY')}
          </span>
        </Meta>
      </li>
    ))}
  </List>
);

const ProjectHeading = styled.h3`
  font-variant: small-caps;
  font-size: 0.7em;
`;

const Index = ({ data, pageContext }) => {
  const prevURL =
    pageContext.currentPage === 2 ? '' : `/page/${pageContext.currentPage - 1}`;

  return (
    <IndexApp>
      <Page title="travisgeis.com">
        <Titles style={{ marginBottom: '4em' }}>
          <h1>
            <TitleLink to="/" title="Home Page">
              travisgeis.com
            </TitleLink>
            <TitleLink to="/contact" title="Contact Me!">
              <FaComment
                style={{
                  verticalAlign: 'top',
                  fontSize: '50%',
                  color: 'var(--accent-color)',
                }}
              />
            </TitleLink>
          </h1>
        </Titles>
        <IndexGrid>
          <MainColumn>
            <ProjectHeading>Blog</ProjectHeading>
            <NodeList nodes={data.posts?.nodes} />
          </MainColumn>
          <RightRail>
            <ProjectHeading>Recent Projects</ProjectHeading>
            <ProjectList projects={data.projects?.nodes} />
          </RightRail>
        </IndexGrid>
        <Pagination>
          <span>
            {pageContext.currentPage !== 1 && (
              <Link to={`/${prevURL}`}>Previous</Link>
            )}
          </span>
          <span>
            {pageContext.currentPage} of {pageContext.numPages}
          </span>
          <span>
            {pageContext.currentPage !== pageContext.numPages && (
              <Link to={`/page/${pageContext.currentPage + 1}`}>Next</Link>
            )}
          </span>
        </Pagination>
      </Page>
    </IndexApp>
  );
};

export default Index;

export const pageQuery = graphql`
  query indexQuery($limit: Int!, $skip: Int!) {
    posts: allPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title
        lede
        slug
        category
        date
        project {
          id
          name
          slug
        }
        path: gatsbyPath(filePath: "/{post.slug}")
      }
    }
    projects: allProject(
      sort: { fields: fields___lastPostDate, order: DESC }
      limit: 10
    ) {
      nodes {
        id
        slug
        fields {
          numPosts
          lastPostDate
        }
      }
    }
  }
`;
